import clsx from "clsx"
import { FC } from "react"
import Hypertext from "../buttons/Hypertext"
import Icon, { IconProps } from "../icon/Icon"
import ApiErrorToast from "./ApiErrorToast"
import { Toast, ToastType } from "./toastTypes"

type GenericToastProps = {
  type: ToastType
  toast?: Toast
}

const typeToIcon: { [key in ToastType]: IconProps["icon"] } = {
  error: "warning",
  info: "info",
  success: "check",
  warn: "exclamation"
}

const typeToIconColor = {
  error: "text-red-600",
  info: "text-blue-600",
  success: "text-green-600",
  warn: "text-orange-600"
} as const

const GenericToast: FC<GenericToastProps> = ({ type, toast }) => {
  const message =
    !toast?.message && type === "success" ? "Your changes have been saved" : toast?.message

  const renderIcon = () => {
    return (
      <div className={clsx("flex", typeToIconColor[type])}>
        {<Icon className="mr-xs" icon={typeToIcon[type]} size={24} type="solid" />}
      </div>
    )
  }

  return (
    <div className={clsx("flex", !toast?.apiError && "max-w-[23rem]")}>
      {renderIcon()}
      <div className="flex flex-col">
        {toast?.title && <p className="mb-2xs p-0 font-medium">{toast?.title}</p>}
        {toast?.apiError && <ApiErrorToast error={toast?.apiError} />}
        {message && <p className="font-normal p-0 text-sm">{message}</p>}
        {toast?.links && toast?.links.length > 0 && (
          <div className="mt-xs flex flex-row text-sm">
            {toast.links.map((link, index) => (
              <div key={"container" + index} className="mr-sm">
                <Hypertext key={"hp" + index} {...link} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default GenericToast
