import { useSuspenseQuery } from "@tanstack/react-query"
import clsx from "clsx"
import { FC, SVGProps, Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"

export const iconCommonNames = [
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-up",
  "audio",
  "audio-description",
  "ballot-check",
  "baseball",
  "basketball",
  "calendar",
  "calendar-details",
  "camera-movie",
  "caret-down",
  "caret-up",
  "chart",
  "check",
  "checkbox-false",
  "checkbox-true",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "circle-small",
  "city",
  "clapperboard-play",
  "clock",
  "cloud",
  "cloud-arrow-down",
  "cloud-arrow-up",
  "cloud-slash",
  "code-branch",
  "court-sport",
  "credit-card",
  "cross",
  "cross-big",
  "cross-circle",
  "current",
  "desktop",
  "destination",
  "destination",
  "display",
  "display-down",
  "download",
  "duplicate",
  "ear-deaf",
  "edit",
  "ellipsis",
  "error",
  "exclamation",
  "exit",
  "external",
  "eye",
  "forbidden",
  "futbol",
  "globe",
  "globe_2",
  "grip",
  "headset",
  "history",
  "home",
  "info",
  "key",
  "legend",
  "library",
  "link",
  "list-check",
  "list-timeline",
  "lock",
  "magnifying-glass-dollar",
  "megaphone",
  "menu",
  "message-lines",
  "microchip",
  "mini-setting",
  "mobile",
  "music",
  "no-video",
  "pause",
  "play",
  "plus",
  "processing",
  "qrcode",
  "question-mark",
  "quortex",
  "rocket",
  "rotate",
  "search",
  "setting",
  "share",
  "shield",
  "shield-check",
  "shield-uncheck",
  "slider",
  "sort",
  "sportsball",
  "stop",
  "stream-switch",
  "transcoding",
  "trash",
  "turn-down",
  "tv",
  "unlink",
  "upload",
  "user",
  "video",
  "warning",
  "waveform-lines",
  "waves-sine",
  "window"
] as const
export const iconRegularNames = [...iconCommonNames] as const
export const iconSolidNames = [...iconCommonNames] as const
export type IconRegularName = (typeof iconRegularNames)[number]
export type IconSolidName = (typeof iconSolidNames)[number]

export type IconSize = 16 | 20 | 24 | 32 | 48 | 64
export type IconProps = {
  className?: string
  "data-testid"?: string
  svgProps?: SVGProps<SVGSVGElement>
  size: IconSize
} & (
  | {
      type: "solid"
      icon: IconSolidName
    }
  | {
      type: "regular"
      icon: IconRegularName
    }
)

const sizeToClass = {
  16: "w-4 h-4",
  20: "w-5 h-5",
  24: "w-6 h-6",
  32: "w-8 h-8",
  48: "w-12 h-12",
  64: "w-16 h-16"
}

const IconElement: FC<IconProps> = ({
  "data-testid": dataTestId,
  icon,
  type,
  className,
  svgProps,
  size
}) => {
  const { data: SvgIcon } = useSuspenseQuery({
    queryFn: async () => (await import(`./icons/${type}/ic_${icon}.svg?react`)).default,
    queryKey: ["icon", type, icon]
  })

  return (
    <div data-testid={dataTestId}>
      <SvgIcon {...svgProps} className={clsx(sizeToClass[size], className)} />
    </div>
  )
}

const Icon: FC<IconProps> = props => (
  <Suspense fallback={<div className={clsx(sizeToClass[props.size], props.className)} />}>
    <ErrorBoundary
      fallbackRender={() => (
        <div className={props.className}>
          <div className="h-full w-full border border-grey-900" />
        </div>
      )}
    >
      <IconElement {...props} />
    </ErrorBoundary>
  </Suspense>
)

export default Icon
