import clsx from "clsx"
import { FC, PropsWithChildren } from "react"
import Button from "src/components/base/buttons/Button"
import Icon from "../icon/Icon"

type Variant = "default" | "legacyDialog" | "legacyDashboard" | "wizard" | "centered"

export type Props = PropsWithChildren & { className?: string } & {
  backUrl?: string
  variant?: Variant
  size?: "s" | "m" | "l" | "xl" | "full" | "medium" | "wide" | number // number is in rem
}

const variantToCls = {
  default: "",
  legacyDashboard: clsx("pb-16 desktop:pb-12 desktop:pt-8"),
  legacyDialog: clsx("pb-16 desktop:pb-12 desktop:pt-10"),
  wizard: clsx("pb-16 desktop:pb-12 desktop:pt-6")
} as const

const maxWidthTocls = {
  full: "",
  l: clsx("max-w-layout-l"),
  m: clsx("max-w-layout-m"),
  medium: clsx("max-w-3xl"),
  s: clsx("max-w-layout-s"),
  wide: clsx("max-w-7xl"),
  xl: clsx("max-w-layout-xl")
} as const

// NB: the relative positionning below is needed to help children position absolute elements inside the container
// DO NOT REMOVE IT
const PageContainer: FC<Props> = ({
  backUrl,
  className,
  children,
  size = "full",
  variant = "default"
}) =>
  variant === "centered" ? (
    <div className={clsx("h-full", className)}>
      <div
        className={clsx(
          "relative mx-auto grid h-full place-items-center",
          typeof size === "string" && maxWidthTocls[size]
        )}
        style={typeof size === "number" ? { maxWidth: `${size}rem` } : undefined}
      >
        <div className={clsx("relative pb-6 pt-3 desktop:pb-12 desktop:pt-6")}>{children}</div>
      </div>
    </div>
  ) : (
    <div
      className={clsx(
        variantToCls[variant],
        className,
        backUrl ? "p-sm" : "p-md pt-xl desktop:p-xl"
      )}
    >
      <div
        className={clsx("relative mx-auto h-full", typeof size === "string" && maxWidthTocls[size])}
        style={typeof size === "number" ? { maxWidth: `${size}rem` } : undefined}
      >
        {backUrl && (
          <Button
            className="mb-sm"
            color="tertiary"
            data-testid="back-btn"
            search={old => ({ ...old })}
            to={backUrl}
            type="link"
          >
            <Icon icon="arrow-left" size={16} type="solid" />
            Back
          </Button>
        )}
        {children}
      </div>
    </div>
  )

export default PageContainer
