import { MakeGenerics, useSearch } from "@tanstack/react-location"
import { useApi } from "src/hooks/api"
import { queryClient } from "./client"
import { getQueryKey, useGetQuery, usePatchQuery, usePostQuery, useRouteParams } from "./helpers"
import { OrgParams } from "./types"
import { getMeUrl } from "./users"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /organization/?org=${orgUuid} */
export const getOrganizationUrl = ({ orgUuid }: Required<OrgParams>) =>
  `/organization/?org=${orgUuid}`

/** @returns /organization/info/?org=${orgUuid} */
export const getOrganizationInfoUrl = ({ orgUuid }: Required<OrgParams>) =>
  `/organization/info/?org=${orgUuid}`

/** @returns /organization/finalize/?org=${orgUuid} */
export const getFinalizeOrganizationUrl = ({ orgUuid }: Required<OrgParams>) =>
  `/organization/finalize/?org=${orgUuid}`

/** @returns /organization/terms_of_service/?org=${orgUuid} */
export const getTermsOfServiceUrl = ({ orgUuid }: Required<OrgParams>) =>
  `/organization/terms_of_service/?org=${orgUuid}`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useOrganizationInfo = (params?: OrgParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationInfoUrl(mergedParams)

  return useGetQuery<api.user.OrganizationInfo>("user", url)
}

export const useUpdateOrganizationInfo = (params?: OrgParams) => {
  const mergedParams = useRouteParams(params)
  const orgUrl = getOrganizationInfoUrl(mergedParams)
  const meUrl = getMeUrl(mergedParams)
  const { "x-amzn-marketplace-token": awsMarketplaceToken } = useSearch<AwsMarketplaceGenerics>()

  const url = awsMarketplaceToken
    ? `${orgUrl}&x-amzn-marketplace-token=${encodeURIComponent(awsMarketplaceToken)}`
    : orgUrl

  type TRequest = DeepPartial<api.user.OrganizationInfo>
  type TResponse = api.user.OrganizationInfo

  return usePatchQuery<TRequest, TResponse>("user", url, [meUrl])
}

export const useCreateOrganization = (params?: OrgParams) => {
  const mergedParams = useRouteParams(params)
  const orgUrl = getOrganizationUrl(mergedParams)
  const orgInfoUrl = getOrganizationInfoUrl(mergedParams)
  const meUrl = getMeUrl(mergedParams)
  const { "x-amzn-marketplace-token": awsMarketplaceToken } = useSearch<AwsMarketplaceGenerics>()

  const url = awsMarketplaceToken
    ? `${orgUrl}&x-amzn-marketplace-token=${encodeURIComponent(awsMarketplaceToken)}`
    : orgUrl

  type TRequest = DeepPartial<api.user.OrganizationInfo>
  type TResponse = api.user.OrganizationInfo

  return usePostQuery<TRequest, TResponse>("user", url, [meUrl, orgInfoUrl])
}

export const useAcceptTermsOfService = (params?: OrgParams) => {
  const mergedParams = useRouteParams(params)
  const url = getTermsOfServiceUrl(mergedParams)
  const orgInfoUrl = getOrganizationInfoUrl(mergedParams)
  const { jsonPost } = useApi("user")

  type TRequest = DeepPartial<api.user.Tos>
  type TResponse = api.user.Tos

  return {
    mutationFn: (data: TRequest) => jsonPost<TResponse>(url, data),
    onSuccess: async (data: TResponse) => {
      await queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", orgInfoUrl) })
    }
  } as const
}

export const useFinalizeOrganization = (params?: OrgParams) => {
  const mergedParams = useRouteParams(params)
  const url = getFinalizeOrganizationUrl(mergedParams)
  const meUrl = getOrganizationInfoUrl(mergedParams)

  type TRequest = {}
  type TResponse = api.user.OrganizationInfo

  return usePostQuery<TRequest, TResponse>("user", url, [meUrl])
}

//--------------------------------------------------------------------------------------------------
// Helpers
//--------------------------------------------------------------------------------------------------

export type AwsMarketplaceGenerics = MakeGenerics<{
  Search: { "x-amzn-marketplace-token"?: string }
}>
