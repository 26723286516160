import { QueryFunctionContext } from "@tanstack/react-query"
import { useApi } from "src/hooks/api"
import { useMatchesParams } from "src/hooks/router"
import { ApiType } from "src/services/api"
import { queryClient } from "./client"

export const useRouteParams = <TParams>(overrides?: TParams) => ({
  ...(useMatchesParams() as Required<TParams>),
  ...overrides
})

export const useGetQuery = <TResponse>(
  api: ApiType,
  url: string,
  options?: { headers?: Record<string, string> }
) => {
  const { jsonGet } = useApi(api)
  const { headers } = options || {}

  return {
    queryFn: ({ signal }: QueryFunctionContext) => jsonGet<TResponse>(url, { headers, signal }),
    queryKey: getQueryKey(api, url)
  } as const
}

export const useGetBase64ImageQuery = <TResponse>(
  api: ApiType,
  url: string,
  options?: { headers?: Record<string, string> }
) => {
  const { base64ImageGet } = useApi(api)
  const { headers } = options || {}

  return {
    queryFn: ({ signal }: QueryFunctionContext) =>
      base64ImageGet<TResponse>(url, { headers, signal }),
    queryKey: getQueryKey(api, url)
  } as const
}

export const usePostQuery = <TRequest extends {}, TResponse extends { uuid: string }>(
  api: ApiType,
  parentUrl: string,
  toRefetchUrls: string[],
  options?: {
    headers?: Record<string, string>
    onSuccess?: (data: TResponse) => Promise<void> | void
  }
) => {
  const { jsonPost } = useApi(api)
  const { headers } = options || {}

  return {
    mutationFn: (data: TRequest) => jsonPost<TResponse>(parentUrl, data, headers),
    onSuccess: async (data: TResponse) => {
      const [path, queryString] = parentUrl.split("?")
      queryClient.setQueryData(getQueryKey(api, `${path}/${data.uuid}/?${queryString}`), data)
      await Promise.all([
        ...toRefetchUrls.map(toRefetchUrl =>
          queryClient.refetchQueries({ exact: true, queryKey: getQueryKey(api, toRefetchUrl) })
        ),
        options?.onSuccess?.(data)
      ])
    }
  } as const
}

export const usePatchQuery = <TRequest extends {}, TResponse extends {}>(
  api: ApiType,
  url: string,
  toRefetchUrls: string[],
  options?: {
    headers?: Record<string, string>
    onSuccess?: (data: TResponse) => Promise<void> | void
  }
) => {
  const { jsonPatch } = useApi(api)
  const { headers } = options || {}

  return {
    mutationFn: (data: TRequest) => jsonPatch<TResponse>(url, data, headers),
    onSuccess: async (data: TResponse) => {
      queryClient.setQueryData(getQueryKey(api, url), data)
      await Promise.all([
        ...toRefetchUrls.map(toRefetchUrl =>
          queryClient.refetchQueries({ exact: true, queryKey: getQueryKey(api, toRefetchUrl) })
        ),
        options?.onSuccess?.(data)
      ])
    }
  } as const
}

export const usePutQuery = <TRequest extends {}, TResponse extends {}>(
  api: ApiType,
  url: string,
  toRefetchUrls: string[],
  options?: {
    headers?: Record<string, string>
    onSuccess?: (data: TResponse) => Promise<void> | void
  }
) => {
  const { jsonPut } = useApi(api)
  const { headers } = options || {}

  return {
    mutationFn: (data: TRequest) => jsonPut<TResponse>(url, data, headers),
    onSuccess: async (data: TResponse) => {
      queryClient.setQueryData(getQueryKey(api, url), data)
      await Promise.all([
        ...toRefetchUrls.map(toRefetchUrl =>
          queryClient.refetchQueries({ exact: true, queryKey: getQueryKey(api, toRefetchUrl) })
        ),
        options?.onSuccess?.(data)
      ])
    }
  } as const
}

export const useDeleteQuery = (
  api: ApiType,
  url: string,
  toRefetchUrls: string[],
  options?: {
    confirmArg?: string
    headers?: Record<string, string>
    onSuccess?: () => Promise<void> | void
  }
) => {
  const { jsonDelete } = useApi(api)
  const { confirmArg, headers } = options || {}

  return {
    mutationFn: () => jsonDelete(url + (confirmArg ? `&${confirmArg}` : ""), headers),
    onSuccess: async () => {
      await Promise.all([
        ...toRefetchUrls.map(toRefetchUrl =>
          queryClient.refetchQueries({ exact: true, queryKey: getQueryKey(api, toRefetchUrl) })
        ),
        options?.onSuccess?.()
      ])

      setTimeout(() => queryClient.removeQueries({ queryKey: getQueryKey(api, url) }), 1_000)
    }
  } as const
}

export const getQueryKey = (api: ApiType, url: string) => {
  const [pathName, queryString] = url.split("?")
  const urlSearchParams = new URLSearchParams(queryString)
  const org = urlSearchParams.get("org")
  urlSearchParams.delete("org")

  // Leading and trailing slashes are required by all our APIs
  if (!pathName?.startsWith("/")) throw new Error(`URLs must start with a slash: ${pathName}`)
  if (!pathName?.endsWith("/")) throw new Error(`URLs must end with a slash: ${pathName}`)

  let filteredPath = pathName

  // Remove /organizations/:org because we insert ["organizations", org] anyway
  filteredPath = filteredPath.replace(`/organizations/${org}/`, "/")

  // Remove leading and trailing slashes so to not pollute the key with empty slots
  filteredPath = filteredPath.slice(1, -1)

  // Transform multi-valued query parameters into a single object
  const params = Array.from(urlSearchParams.entries()).reduce(
    (acc, [key, value]) => {
      acc[key] = acc[key] ? `${acc[key]},${value}` : value
      return acc
    },
    {} as Record<string, string>
  )

  return [
    api,
    ...(org ? ["organizations", org] : []),
    ...filteredPath.split("/").map(decodeURIComponent),
    ...(urlSearchParams.size > 0 ? [params] : [])
  ]
}

/** Disable auto-publish of OTT endpoints */
export const disableAutoPublishHeader = { "Publishing-Mode": "explicit" }
